import styles from "./Card.module.css";
const Card = ({portfolioItem}) =>{

return (
    
    <div className={styles.container}>
        <div className={styles.info}>
            <h3 className={styles.title}>{portfolioItem.title}</h3>
            <p className={styles.description}>{portfolioItem.description}</p>
           
            <div className={styles.links}>
                <a className={styles.urlLink}href={portfolioItem.urlLink} target="_blank">
                        View In Play Store
                </a>
                {portfolioItem.iosLink && 
                <a className={styles.urlLink}href={portfolioItem.iosLink} target="_blank">
                    View In App Store
                </a>
                }
            </div>

        </div>
        <div className={styles.imageContainer}><img
            className={styles.image}
            src={portfolioItem.image}
            alt="application image"/>
        </div>
    </div>
    );
};
export default Card;