import ContactButton from "../UI/ContactButton";
import Header from "../components/Header";
import About from "../components/About";
import ServiceOffered from "../components/ServiceOffered";
import Portfolio from "../components/Portfolio";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import NewPortfolio from "../components/newPortfolio/NewPortfolio";

const Home = () => {
  const buttonPermanent = true;
  return (
    <>
      <ContactButton buttonPermanent={buttonPermanent} />
      <Header />
      <About />
      <ServiceOffered />
      <Portfolio />
      <Testimonials />
      <Footer />
    </>
  );
};
export default Home;
