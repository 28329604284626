import Slider from "../UI/Slider";
import styles from "./Portfolio.module.css";
import Img1 from "../images/rms-2.0-screenshot.webp";
import Img2 from "../images/giraffeg4.webp";
import Img3 from "../images/bardeum-screenshot.webp";
import Img4 from "../images/crosstails-screenshot.png";
import Img5 from '../images/mypreemie-screenshot.png';

const Portfolio = () => {
  const portfolioItems = [
    {
      image: Img1,
      title: "Retail Mastery System 2.0",
      urlLink: "https://play.google.com/store/apps/details?id=com.whizbangtraining.rms.android",
      iosLink:"",
      description:
      "The Retail Mastery System 2.0 is the easiest, fastest, and best way to grasp the 11 Critical Retail Skills necessary to run a retail business.",
    },
    {
      image: Img2,
      title: "Giraffe G4",
      urlLink:"https://play.google.com/store/apps/details?id=com.giraffe.g4userapp",
      iosLink:"https://apps.apple.com/us/app/giraffeg4/id1585801023",
      description: "GiraffeG4 Sentinel User App will warn the operator of a tall vehicle if they are approaching a 'Low Clearance' obstacle.",
    },
    {
      image: Img3,
      title: "Bardeum",
      urlLink:
        "https://play.google.com/store/apps/details?id=com.museumedutainment.android",
      iosLink:"",
      description: "Don't Just See the Sites; Step Inside their Stories!",
    },
    {
      image: Img4,
      title: "CrossTails",
      urlLink:"https://play.google.com/store/apps/details?id=com.crosstails.android",
      iosLink:"https://apps.apple.com/sa/app/cross-tails/id6478471119",
      description: "Igniting a world where every pet thrives, one safe meal at a time.",
    },
    {
      image: Img5,
      title: "MyPreemie",
      urlLink:"https://play.google.com/store/apps/details?id=com.ubitrixbd.mypreemie",
      iosLink:"https://apps.apple.com/us/app/mypreemie-app/id931150109",
      description: "A complete toolkit for the unique practical and emotional needs of families of premature babies.",
    },
  ];
  return (
    <div className={styles["pf-section"]}>
      <h2>Portfolio</h2>
      <Slider portfolioItems={portfolioItems}/>
      
    </div>
  );
};
export default Portfolio;
